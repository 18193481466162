/**************** RESET CSS START ****************/
#wrapper,
body {
	margin: 0;
	padding: 0;
}
#header,
#wrapper {
	width: 100%;
	height: auto;
	float: left;
}
#slider,
#wrapper,
.picBlock,
.pro_pic {
	overflow: hidden;
}
.breadcrumb>li>a:hover,
.hdrContactInfo ul li a,
.hdrContactInfo ul li a:hover,
.mm-dropdown ul li a:hover,
p.linksBlock a:hover {
	text-decoration: none;
}
body {
	background-color: #fbfbfb;
	font-family: Roboto, sans-serif;
	color: #5f5846;
}
.button01,
p a {
	color: #614b38;
}
.img-responsive,
img,
img:focus {
	border: 0;
	outline: 0;
	display: inline-block;
}
.hdrTop .tagLine:before,
.hdrTop:before {
	content: "";
	display: block;
	position: absolute;
}
p {
	line-height: 24px;
}
p strong {
	font-weight: 700;
}
ul li {
	padding: 0 0 15px;
	margin: 0;
}
@media screen and (min-width:320px){
	.button01,
	.button02 { padding: 10px 25px; }
}
@media screen and (min-width:768px){
	.button01,
	.button02 { padding: 13px 30px; }
}
.button01,
.button02 {
	font-size: 15px;
	font-weight: 500;
	margin: 0;
	text-transform: uppercase;
}
.button01 {
	background-color: #c8bfae;
	border: 2px solid #c8bfae;
	border-radius: 0;
	transition: background-color .4s ease;
	-webkit-transition: background-color .4s ease;
	-moz-transition: background-color .4s ease;
	-o-transition: background-color .4s ease;
	-ms-transition: background-color .4s ease
}
.button01:hover,
.button02 {
	background-color: #614b38;
	color: #FFF
}
.button02 {
	border: 2px solid #614b38;
	border-radius: 0;
	transition: background-color .4s ease;
	-webkit-transition: background-color .4s ease;
	-moz-transition: background-color .4s ease;
	-o-transition: background-color .4s ease;
	-ms-transition: background-color .4s ease
}
.button02:hover {
	background-color: #c8bfae;
	color: #614b38
}
/**************** RESET CSS END ****************/

/*************** Header CSS Start *******************/
@media screen and (min-width:320px){
body { font-size: 15px; }
p { margin-bottom: 15px; }
.hdrContactInfo,
.logo_trimurti,
.hdrTop { display: none }
.hdrBottom { position: relative; }
.hdrBottom .logo { width: 60%; margin: 10px 0; }
.navbar-nav li { padding: 0 15px; border-left: 0 }
.navbar-nav li:before { display: none }
.navbar-toggle {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 0;
	bottom: 15px;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background-color: #614b38;
	color: #c8bfae;
	font-size: 29px
}
.navbar-toggle span {
	display: block;
	width: 27px;
	height: 3px;
	margin: 0 auto;
	background-color:#c8bfae;
}
.navbar-toggle span:not(:first-child) { margin-top: 5px; }
.navbar-collapse {
	padding: 0;
	border: 0;
	box-shadow: none
}
.navbar-nav {
	width: 100%;
	margin: 0;
	padding: 0
}
.navbar-nav li:first-child {
	padding-left: 15px;
	padding-right: 15px
}
.navbar-nav li a {
	padding: 10px 0;
	font-size: 15px;
}
.mm-dropdown ul {
	width: 100%;
	position: static;
	display: block;
	padding: 0;
}
.mm-dropdown ul li {
	padding: 0!important;
	border: 0
}
.mm-dropdown ul li a { padding: 8px 15px; }
.hdrContactInfo ul li.trimurti { display: none; }
}

@media screen and (min-width:480px){
	.navbar-toggle { bottom: 22px; }
	.hdrBottom .logo { width: 40%; }
	.hdrContactInfo { width: 100%; }
	.hdrContactInfo ul {
		width: 100%;
		text-align: center;
		padding: 0;
		margin: 20px 0;
	}
	.hdrContactInfo ul li {
		display: inline-block;
		vertical-align: top;
		position: relative;
		border-right: 1px solid rgba(200, 191, 174, .5);
		margin: 0;
		padding: 15px 20px 10px;
		text-align: left;
	}
	.hdrContactInfo ul li:first-child { border-left: 1px solid rgba(200, 191, 174, .5); }
	.hdrContactInfo ul li:last-child { border: 0; }
	.hdrContactInfo ul li .contact-icon,
	.hdrContactInfo ul li .contact-info {
		display: inline-block;
		vertical-align: top;
	}
	.hdrContactInfo ul li .contact-icon { margin: 5px 12px 0 0; }

	.hdrTop .hdrSocialIcons ul li {
		display: inline-block;
		padding: 0 0 0 15px;
		margin: 0
	}
	.hdrContactInfo ul li.trimurti img {
		width: 55%;
		margin-left: 10px;
	}
}

@media screen and (min-width:768px){
.hdrTop { display: block; height: 39px; }
.hdrContactInfo ul li:first-child { border-left: 1px solid rgba(200, 191, 174, .5); }
.hdrContactInfo ul li.trimurti img { width: auto; margin-top: -22px; }
.hdrContactInfo ul li.trimurti { display: inline-block; }
.hdrContactInfo,
.logo_trimurti { display: inline-block; }
.hdrBottom .logo {
	width: 100%;
	text-align: center;
	margin: 20px 0 0;
}
.hdrBottom .logo, .hdrContactInfo {
	float: none;
	text-align: center;
}
.navbar li:before {
	content: "";
	width: 1px;
	height: 20px;
	float: left;
	background-color: #c8bfae;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -10px
}
.navbar li a { font-size: 17px; }
.navbar li a:focus,
.navbar li a:hover {
	background-color: transparent;
	color: #FFF
}
.nav>li, .nav>li>a {
	display: block;
	position: relative;
}
.navbar li { padding: 0 22px; }
.navbar-nav li a { padding: 15px 0; }
.navbar li:first-child:before {
	display: none
}
.navbar li:hover>a {
	color: #FFF
}
.navbar .active>a {
	background-color: transparent;
	color: #FFF
}
.mm-dropdown ul {
	display: none;
	width: 200px;
	background-color: #614b38;
	position: absolute;
	left: 0;
	top: 50px;
	z-index: 9999;
	list-style-type: none;
	margin: 0;
	padding: 0
}
.mm-dropdown:hover ul {
	display: block
}
.mm-dropdown ul li {
	padding: 0;
	margin: 0;
	border-top: 1px solid #c8bfae
}
.mm-dropdown ul li:first-child {
	border-top: 0
}
.mm-dropdown ul li:before {
	display: none
}
.mm-dropdown ul li a { padding: 10px 15px; }
}

@media screen and (min-width:992px){
	body { font-size: 17px; }
	p { margin-bottom: 20px; }
	#header {
		background-color: #FFF;
		border-top: 6px solid #5f5846;
		margin: 0;
		padding: 0
	}
	.hdrTop .hdrSocialIcons {
		height: 39px;
		line-height: 32px
	}
	.hdrTop .hdrSocialIcons ul {
		float: right;
		display: inline-block;
		list-style-type: none;
		margin: 0;
		padding: 0
	}
	.hdrTop .hdrSocialIcons ul li a {
		color: #c8bfae;
		font-size: 17px
	}
	.hdrTop .hdrSocialIcons ul li a:hover {
		color: #FFF
	}
	.hdrBottom {
		width: 100%;
		float: left;
		margin: 0;
		padding: 0
	}
	.hdrContactInfo ul {
		display: inline-block;
		list-style-type: none;
		margin: 20px 0;
		padding: 0
	}
	.hdrContactInfo ul li.trimurti {
		padding-left: 0;
		margin-left: 15px
	}
	.logo_trimurti {
		width: 7%;
		margin-left: 10px
	}
	.navbar-collapse { padding: 0 15px; }
	.navbar:before {
		width: 50px;
		height: 65px;
		background: url(../img/triangle_pic.jpg) left top no-repeat;
		position: absolute;
		left: 0;
		top: 0
	}
	.mm-dropdown ul { top: 64px; }
	.navbar li a {
		text-transform: uppercase;
		color: #c8bfae;
		font-size: 17px;
		font-weight: 500;
		text-shadow: none;
		margin: 0;
		padding: 22px 0
	}
	.hdrBottom .logo {
		float: left;
		margin: 20px 0 0;
		padding: 0;
		width: auto;
	}
	.hdrContactInfo {
		float: right;
		width: auto;
	}
}

#header {
    background-color: #FFF;
    border-top: 6px solid #5f5846;
    margin: 0;
    padding: 0;
}
.hdrTop .tagLine,
.hdrTop:before {
	height: 39px;
	background-color: #5f5846
}
.hdrTop:before {
	width: 50%;
	left: 50%
}
.hdrTop .tagLine {
	line-height: 30px;
	color: #fff;
	font-weight: 300;
	padding-left: 50px
}
.hdrTop .tagLine:before {
	width: 0;
	height: 0;
	border-top: 39px solid;
	border-left: 30px solid transparent;
	left: -30px;
	border-top-color: #5f5846
}
.hdrBottom {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
}
.hdrContactInfo ul li.trimurti { margin-left: 0; padding-left: 0; }
.hdrContactInfo ul li span {
	display: block;
	color: #c8bfae;
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase
}
.hdrContactInfo ul li a {
	font-size: 15px;
	font-weight: 500;
	color: #5f5846
}
.hdrContactInfo ul li.contact { padding-right: 15px; }

/***** Navbar CSS Start ****/
.navbar li:before,
#slider .carousel-inner .item:before,
.fullBg:before, .navbar li:before,
.navbar:before,
.picBlock:before {
    content: "";
}
.navbar {
	width: 100%;
	float: left;
	min-height: inherit;
	background-color: #614b38;
	border: 0;
	border-radius: 0;
	margin: 0;
	padding: 0
}
.navbar li {
	margin: 0;
}
.navbar li a {
	text-transform: uppercase;
	color: #c8bfae;
	font-weight: 500;
	text-shadow: none;
}
.navbar li.active > a {
    background-color: transparent;
    color: #FFF;
}
.mm-dropdown ul li a {
	display: block;
	font-weight: 400;
	text-transform: none
}
.nav>li>a:focus, .nav>li>a:hover {
	text-decoration: none;
	background-color: transparent;
	outline: 0;
}
/***** Navbar CSS End ****/

/*************** Header CSS End *******************/

#footer h4,
.banner .mainTitle h1,
.pro_info a {
	text-transform: uppercase
}

/*************** Homepage Slider CSS Start *******************/
@media screen and (min-width:320px){
	#slider .carousel-caption .caption_inner h2 { font-size: 20px; }
	#slider .carousel-inner .item { height: 130px; }
	#slider .carousel-caption .caption_inner p { font-size: 16px }
	#slider .carousel-caption .caption_inner a,
	#slider .carousel-caption .caption_inner p { display: none; }
}
@media screen and (min-width:480px){
	#slider .carousel-inner .item { height: 250px; }
	#slider .carousel-caption .caption_inner h2 { font-size: 26px; padding-bottom: 10px; }
	#slider .carousel-caption .caption_inner p { width: 70%; }
	#slider .carousel-caption .caption_inner p { display: block; }
	#slider .carousel-caption .caption_inner a { display: inline-block; }
}
@media screen and (min-width:768px){
	#slider .carousel-inner .item { height: 350px; }
	#slider .carousel-caption .caption_inner h2 { font-size: 32px; padding-bottom: 15px; }
	#slider .carousel-caption .caption_inner p { width: 70%; margin-bottom: 15px }
}
@media screen and (min-width:992px){
	#slider .carousel-caption .caption_inner h2 { font-size: 40px; padding-bottom: 25px; }
	#slider .carousel-caption .caption_inner p { width: 56%; margin-bottom: 35px; }
	#slider .carousel-indicators li { border-color: #c8bfae; }
	#slider .carousel-indicators .active { background-color: #c8bfae;  }
}
@media screen and (min-width:1200px){
	#slider .carousel-inner .item { height: 500px; }
	#slider .carousel-caption { width: 100%; }
}
#slider {
	width: 100%;
	float: left;
	margin: 0;
	padding: 0
}
#slider .carousel-inner .item {
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	overflow: hidden
}
#slider .carousel-inner .item .container { display: table; height: 100%; }
#slider .carousel-inner .item:before {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .4);
	position: absolute;
	left: 0;
	top: 0;
}
#slider .carousel-caption {
	position: relative;
	left: 0;
	top: 0;
	text-shadow: none;
	text-align: left;
	padding: 0;
	display: table;
	height: 100%
}
#slider .carousel-caption .caption_inner {
	display: table-cell;
	height: 100%;
	vertical-align: middle
}
#slider .carousel-caption .caption_inner h2 {
	color: #FFF;
	font-weight: 400;
	margin: 0;
}
#slider .carousel-caption .caption_inner p {
	font-size: 18px;
	font-weight: 300;
	color: #FFF;
	line-height: 30px;
}
.carousel-indicators li,
.carousel-indicators .active {
    width: 10px;
    height: 10px;
    margin: 3px;
}
/*************** Homepage Slider CSS End *******************/

/*************** Homepage Fullwidth-Intro CSS Start *******************/
@media screen and (min-width:320px){
	.fullWidth { padding: 40px 0; }
	.picBlock { margin-top: 30px; }
	.fullWidth .title { padding-bottom: 0; }
	.title h1 { font-size: 24px; line-height: 30px; }
	.productBox { margin: 20px 0 10px; }
	.pro_info h3 { font-size: 24px; }
	.title h1 small { font-size: 22px; }
	.fullWidth .contactInfo { margin-bottom: 20px; }
	.fullWidth .contactInfo p { text-align: left; }
	.fullWidth .btn.button01 { display: table; margin: 0 auto; }
	.enquiry_form .form-group .btn.button01 { display: inline-block; }
}
@media screen and (min-width:768px){
	.fullWidth { padding: 50px 0; }
	.title h1 { font-size: 32px; line-height: 40px; }
	.picBlock { margin-top: 0; }
	.fullWidth .title { padding-bottom: 20px; }
	.productBox { margin: 30px 0; }
	.title h1 small { font-size: 28px; }
	.fullWidth p,
	.fullWidth h1 { text-align: left; }
	.fullWidth .btn.button01 { display: inline-block; }
}
@media screen and (min-width:992px){
	.pro_info h3 { font-size: 25px; }
}
.fullWidth {
	width: 100%;
	float: left;
	margin: 0;
}
.fullBg {
	background: url(../img/bigBanner_01.jpg) 50% 50% no-repeat;
	background-size: cover;
	height: auto;
	position: relative;
	z-index: 0
}
.fullWidth.fullBg .row { display: flex; flex-wrap: wrap; }
@media screen\0 {
    .fullWidth.fullBg .row { display: block; flex-wrap: inherit; }
}
.fullBg:before {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .8);
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1
}
.title {
	width: 100%;
	float: left;
}
.title h1 {
	font-weight: 400;
	color: #614b38;
	margin: 0;
	padding: 0 0 15px
}
.title h1 small {
	display: block;
	font-weight: 400;
	color: #5f5846;
	margin-bottom: 5px;
}
.title h2{
	font-size: 22px;
	font-weight: 400;
	margin: 0;
	padding: 0 0 15px
}
.title.colorWhite h2 {
    color: #FFF;
}
.title h2 small {
	display: block;
	font-weight: 400;
	color: #5f5846;
	margin-bottom: 5px;
}

.title.colorWhite p, .title.colorWhite small {
    color: #c8bfae;
	text-align: center;
}

@media(min-width:992px){
	.title h2{
		font-size: 32px;
	}
}
.picBlock {
	position: relative;
	background-color: #000
}
.picBlock:before {
	width: 90%;
	height: 84%;
	border: 5px solid #5f5846;
	position: absolute;
	left: 5%;
	top: 8%
}
.picBlock img {
	transition: all .2s ease-in-out
}
.picBlock img:hover {
	transform: scale(1.1);
	opacity: .4
}
/*************** Homepage Fullwidth-Intro CSS End *******************/

/*************** Homepage Fullwidth-Product CSS Start *******************/
@media screen and (min-width:320px){
	.productBox .col-xs-3 { width: 100%; margin-bottom: 15px; }
	.productBox .col-xs-9 { width: 100%; }
	.productsGallery .item { height: 250px; }
	.linksBlock { margin-top: 15px; }
}
@media screen and (min-width:480px){
	.productBox .col-xs-3 { width: 25%; margin-bottom: 0; }
	.productBox .col-xs-9 { width: 75%; }
}
@media screen and (min-width: 768px) {
	.productsGallery .item { height: 175px; }
	.linksBlock { margin-top: 0; }
}
@media screen and (min-width:992px){
	.productBox .col-xs-3 { width: 50%; margin-bottom: 0; }
	.productBox .col-xs-9 { width: 50%; }
}
@media (max-width:991px){
	.footerBottom .container { display: flex; flex-direction: column-reverse; text-align: center; }
	#footer p.pull-left { margin-top: 10px;}
}
.title.colorWhite p,
.title.colorWhite small {
	color: #c8bfae
}
.title.colorWhite h1 {
	color: #FFF
}
.pro_pic {
	width: 100%;
	border: 2px solid #fff
}
.pro_pic img {
	transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	-ms-transition: all .4s ease-in-out
}
.pro_pic img:hover {
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1)
}
.pro_info h3 {
	color: #FFF;
	font-style: italic;
	position: relative;
	margin: 0 0 20px;
	padding: 0 0 20px
}
.pro_info p { text-align: left; }
.pro_info a,
.pro_info p {
	color: #c8bfae
}
#footer h4:before,
.pro_info h3:before {
	position: absolute;
	content: "";
	left: 0
}
.pro_info h3:before {
	width: 65px;
	height: 1px;
	background-color: #c8bfae;
	bottom: 0
}
.pro_info a {
	font-size: 14px;
	font-weight: 600
}
/*************** Homepage Fullwidth-Product CSS End *******************/

/*************** Enquiry-Section CSS Start *******************/
@media screen and (min-width:320px){
	.contactBlock .media-body {
		width: 100%;
		display: block;
		margin-bottom: 15px;
	}
	.contactBlock .media-right {
		padding-left: 0;
		width: 100%;
		text-align: center;
		display: block;
	}
	.contactBlock h4.media-heading { font-size: 22px; }
	.contactBlock .media-body { text-align: center; }
}
@media screen and (min-width:768px){
	.contactBlock .media-body { display: table-cell; text-align: left; }
	.media-body, .media-left, .media-right {
		display: table-cell;
		vertical-align: middle;
		width: auto;
	}
	.contactBlock .media-right { display: table-cell; padding-left: 50px; }
	.contactBlock h4.media-heading { font-size: 26px; }
}
.contactBlock {
	background-color: #c8bfae;
	padding: 25px 0
}
.contactBlock h4.media-heading {
	font-weight: 400;
	color: #000;
	margin-bottom: 15px;
	padding: 0
}
/*************** Enquiry-Section CSS End *******************/

/*************** Footer CSS Start *******************/
@media screen and (min-width:320px){
	#footer h4 { padding: 15px 0 15px 15px; }
	#footer .col-xs-4 { width: 100%; }
	#footer .hidden-xs { display: block!important; }
	.footerBottom p { width: 100%; text-align: center; }
	.quick_links { padding-left: 15px; }
	ul.footer_links li { margin-bottom: 5px; }
	#footer { padding-top: 25px; }
	#footer .footer_logo img { width: 200px; }
}

@media screen and (min-width:480px){
	#footer h4 { padding: 15px 0 25px 15px; }
	#footer .col-xs-4 { width: 33.33%; }
	ul.footer_links li { margin-bottom: 10px; }
}

@media screen and (min-width:768px){
	.footerBottom p { width: auto; text-align: center; }
	#footer .col-xs-4 { width: 25%; }
	#footer { padding-top: 50px; }
	#footer .footer_logo img { width: 250px; }
}
@media screen and (min-width:992px){
	.quick_links { padding-left: 50px; }
}
#footer,
.contactBlock p {
	margin: 0
}
#footer {
	width: 100%;
	float: left;
	background-color: #FFF;
	border-top: 5px solid #c8bfae;
}
#footer .footer_logo {
	margin-bottom: 15px;
}
#footer p {
	font-weight: 300;
}
#footer h4 {
	font-size: 18px;
	color: #5f5846;
	position: relative;
	line-height: 30px;
	margin: 0;
}
#footer h4:before {
	width: 5px;
	height: 30px;
	background-color: #7f644c;
	top: 15px
}
ul.footer_links {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
ul.footer_links li {
	float: left;
	width: 100%;
	padding: 0;
}
ul.footer_links li a {
	color: #5f5846;
	font-size: 17px;
	font-weight: 300;
}
.sidebar h3,
h2 {
	font-weight: 400;
}
.footerBottom {
	width: 100%;
	float: left;
	background-color: #5f5846;
	margin: 25px 0 0;
	padding: 25px 0;
}
.footerBottom p {
	font-size: 15px;
	color: #c8bfae;
	margin: 0;
	padding: 0;
}
.footerBottom p a {
	color: #FFF;
}
/*************** Footer CSS End *******************/

/*************** Page-Banner CSS Start *******************/
@media screen and (min-width:320px){
	.banner .container .mainTitle h1 { font-size: 32px; }
}
@media screen and (min-width:768px){
	.banner .container .mainTitle h1 { font-size: 40px; }
}
.banner {
	width: 100%;
	height: 150px;
	float: left;
	background-size: cover;
	background-position: left center;
	background-repeat: no-repeat;
	position: relative;
	z-index: 0
}
.banner .container {
	height: 100%;
	display: table
}
.banner .mainTitle {
	width: 100%;
	height: 100%;
	display: table-cell;
	vertical-align: middle
}
.banner .mainTitle h1 {
	color: #FFF;
	font-weight: 400;
	margin: 0;
	padding: 0
}
.banner:before {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .4);
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1
}
/*************** Page-Banner CSS End *******************/

/*************** Inner-page CMS-Content CSS Start *******************/
h2 {
	font-size: 22px;
	color: #614b38;
	margin: 0;
	padding: 0 0 15px
}
@media screen and (min-width:320px){
	h2 { font-size: 22px; }
}
@media screen and (min-width:992px){
	h2 { font-size: 25px; }
}
.innerPic {
	width: 100%;
	display: inline-block;
	padding-bottom: 25px
}
.innerPic iframe { width: 100%; }

/*************** Inner-page CMS-Content CSS End *******************/

/*************** Sidebar-Link CSS Start *******************/
@media screen and (min-width:320px){
	.sidebar h3 { font-size: 19px; padding: 0 15px; }
	.linksBlock ul li a  { padding: 10px 15px; }
}
@media screen and (min-width:992px){
	.sidebar h3 { font-size: 22px; }
	.linksBlock ul li a { padding: 10px 20px; }
}
.sidebar {
	width: 100%;
	float: left;
	background-color: #ddd7cb;
	margin-bottom: 30px;
	padding: 0
}
.sidebar h3 {
	background-color: #614b38;
	min-height: 45px;
	color: #ddd7cb;
	line-height: 45px;
	margin: 0;
	padding: 0 20px
}
.linksBlock ul {
	list-style-type: none;
	margin: 0;
	padding: 0
}
.linksBlock ul li {
	display: block;
	border-top: 1px solid #614b38;
	margin: 0;
	padding: 0
}
.linksBlock ul li:first-child { border-top: 0 }
.linksBlock ul li a {
	display: block;
	color: #614b38;
	font-size: 17px;
	font-weight: 300;
}
.linksBlock ul .active a,
.linksBlock ul li a:hover {
	text-decoration: none;
	background-color: #614b38;
	color: #ddd7cb
}
/*************** Sidebar-Link CSS End *******************/

/*************** Sidebar-Slider CSS Start *******************/
.productsGallery .carousel-control-prev,
.productsGallery .carousel-control-next {
	position: absolute;
	top: 50%;
	width: 30px;
	height: 30px;
	background-color: rgba(1, 1, 1, .3);
	margin-top: -15px;
	font-size: 20px;
	color: #FFF;
	text-align: center;
	line-height: 30px
}
.productsGallery svg { display: inline-block; vertical-align: middle; margin-top: -4px; }
.productsGallery .carousel-control-prev { left: 0; }
.productsGallery .carousel-control-prev svg { transform: rotate(-180deg); -webkit-transform: rotate(-180deg); -ms-transform: rotate(-180deg); }
.productsGallery .carousel-control-next { right: 0; }
/*************** Sidebar-Slider CSS End *******************/

/*************** Product-List CSS Start *******************/
.products_list {
	margin: 0 0 30px;
	border-top: 1px solid #ddd7cb;
	padding-top: 30px
}
.products_list:first-child {
	border-top: 0 none;
	padding-top: 0
}
.products_list .pro_pic {
	border-color: #614b38
}
.products_list .pro_info h3 {
	color: #614b38
}
.products_list .pro_info a,
.products_list .pro_info p {
	color: #5f5846
}
/*************** Product-List CSS End *******************/

/*************** Enquiry-Form CSS Start *******************/
@media screen and (min-width:320px){
	.enquiry_form .form-group label { line-height: normal; }
	#contact-form .form-group .form-control,
	.enquiry_form .form-group label,
	.enquiry_form .form-group label { width: 100%; }
	.enquiry_form .form-group label {
		width: 100%;
		line-height: normal;
		margin-bottom: 5px
	}
	.enquiry_form .form-group label.error {
		padding-left: 0;
		width: 100%;
		margin-bottom: 0
	}
	.enquiry_form .form-group .captchaCode { width: 50%; }
	.enquiry_form .form-group .captcha_img { width: 45%; }
	.enquiry_form .form-group .captchaRefresh {
		width: 100%;
		padding-left: 0;
	}
	.enquiry_form .form-group .form-contro,
	#contact-form .form-group .form-control { width: 100%; }
	.enquiry_form .form-group .captcha-err,
	.enquiry_form .form-group .email-err,
	.enquiry_form .form-group label.error,
	#contact-form .form-group .email-err,
	#contact-form .form-group label.error { padding-left: 0; }
}

@media screen and (min-width:768px){
	.enquiry_form .form-group .form-control.captchaCode,
	#contact-form .form-group .form-control.captchaCode { width: 25%; }
	.enquiry_form .form-group .captchaRefresh {
		width: 75%;
		padding-left: 21%
	}
	.enquiry_form .form-group .captcha_img { width: 25%; }
	.enquiry_form .form-group .form-control,
	#contact-form .form-group .form-control { width: 100%; }
	.enquiry_form .form-group label {
		font-weight: 400;
		width: 20%;
		vertical-align: top;
		margin-bottom: 0;
		line-height: 40px;
	}
	.enquiry_form .form-group .captcha-err,
	.enquiry_form .form-group .email-err,
	.enquiry_form .form-group label.error { padding-left: 21%; }
}
.forms {
    background: #f9f9f9;
    padding: 30px;
    box-shadow: 0px 0px 6px 3px #eee;
}
#enquiryvalidate label,
#contact-form label { font-weight: normal; }
.enquiry_form .form-group {
	width: 100%;
	float: left;
	margin-bottom: 15px
}
.enquiry_form .form-group label span,
#enquiryvalidate .form-group label span,
#contact-form .form-group label span { color: #c00; }
.enquiry_form .form-group .captcha-err,
.enquiry_form .form-group .email-err,
.enquiry_form .form-group label.error,
#enquiryvalidate .form-group .captcha-err,
#enquiryvalidate .form-group .email-err,
#contact-form .form-group label.error {
	width: 75%;
	color: #c00;
	line-height: normal;
	display: block;
}
.enquiry_form .form-group .form-control,
#enquiryvalidate .form-group .form-control,
.forms .form-group .form-control {
	border-radius: 0;
	box-shadow: none;
	border-color: #ddd7cb;
	height: 40px;
	font-size: 17px;
	color: #614b38;
	font-weight: 400;
	border-radius: 0;
}
#contact-form .form-group textarea { height: 150px; resize: none; }
.enquiry_form .form-group .form-control::-webkit-input-placeholder,
#enquiryvalidate .form-group .form-control::-webkit-input-placeholde,
#contact-form .form-group .form-control::-webkit-input-placeholder {
	color: #ddd7cb;
}
.enquiry_form .form-group .form-control::-moz-placeholder,
#enquiryvalidate .form-group .form-control::-moz-placeholder,
#contact-form .form-group .form-control::-moz-placeholder {
	color: #ddd7cb;
}
.enquiry_form .form-group .form-control:-ms-input-placeholder,
#enquiryvalidate .form-group .form-control:-ms-input-placeholder,
#contact-form .form-group .form-control:-ms-input-placeholder {
	color: #ddd7cb;
}
.enquiry_form .form-group .form-control:-moz-placeholder,
#enquiryvalidate .form-group .form-control:-moz-placeholder,
#contact-form .form-group .form-control:-moz-placeholder {
	color: #ddd7cb;
}
.enquiry_form .form-group .form-control:focus,
#enquiryvalidate .form-group .form-control:focus,
#contact-form .form-group .form-control:focus {
	border-color: #614b38;
}
.enquiry_form .form-group textarea,
#enquiryvalidate .form-group textarea { height: 100px !important; }
.enquiry_form .form-group .captcha_img { display: inline-block; }
.enquiry_form .form-group .captchaRefresh { display: block; }
/*************** Enquiry-Form CSS End *******************/

/*************** Contact CSS Start *******************/
@media screen and (min-width:320px){
	.contactInfo .col-xs-6 { width: 100% }
}
@media screen and (min-width:480px){
	.contactInfo .col-xs-6 { width: 50% }
}
.contactInfo h4 {
	font-weight: 500;
	font-size: 20px
}
p.linksBlock {
	display: inline-block;
	font-size: 21px;
	background-color: #ddd7cb;
	padding: 0;
	margin: 25px 0 0
}
p.linksBlock a {
	display: block;
	padding: 15px;
	transition: background-color .4s ease;
	-webkit-transition: background-color .4s ease;
	-moz-transition: background-color .4s ease;
	-o-transition: background-color .4s ease;
	-ms-transition: background-color .4s ease
}
p.linksBlock a:hover {
	background-color: #614b38;
	color: #ddd7cb
}
/*************** Contact CSS End *******************/

/*************** Breadcumb CSS Start *******************/
.breadcrumb {
	background-color: transparent;
	border-bottom: 1px solid #ebebeb;
	padding: 0 0 10px
}
.breadcrumb>li {
	position: relative;
	font-size: 15px;
	color: #614b38
}
.breadcrumb>li+li { padding-left: 11px; margin-left: 5px; }
.breadcrumb>li+li:before {
    content: "";
    background: url(../img/double-angle-pointing-to-right.svg) no-repeat left top;
    padding: 0;
    top: 6px;
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: 1;
    left: -3px;
    background-size: cover;
}
.breadcrumb>li>a {
	font-size: 15px;
	color: #c8bfae
}
.breadcrumb>li>a:hover {
	color: #614b38
}
.breadcrumb>.active {
	color: #614b38
}
/*************** Breadcumb CSS End *******************/

.blog-content a{
	font-weight: 600;
	text-decoration: underline;
}
.blogpage h2 a{
	color: #614b38;
}
.blogpage h2 a:hover{
	text-decoration: none;
}

.blogpage .blog-content ul, .blogpage .blog-content ol{
	padding-left: 30px;
}

.blogpage .blog-content ol li{
	padding: 0 0 15px;
}


@media screen and (min-width: 320px){
	.blogpage .blog-content h3{
		font-size: 18px;
	}
}

@media screen and (min-width: 992px){
	.blogpage .blog-content h3{
		font-size: 22px;
	}
}

/*CTA Blog Detail Start*/
.cta-for-blog {
	padding: 30px 15px;
	margin-bottom: 20px;
	margin-top: 20px;
	text-align: center;
}
@media(min-width:992px) {
	.cta-for-blog {
		padding: 30px;
	}
}
.cta-for-blog .cta-title {
	font-size: 18px;
	font-weight: 600;
	line-height: 1.3;
	max-width: 800px;
	margin: 0 auto;
}

.cta-for-blog .sub-title {
	font-size: 16px;
	line-height: 1.3;
	margin-top: 10px;
	max-width: 800px;
	margin: 0 auto;
	margin-top: 15px;
}

@media(min-width:992px) {
	.cta-for-blog .cta-title {
		font-size: 36px;
	}

	.cta-for-blog .sub-title {
		font-size: 30px;
	}
}

.cta-bg-primary {
	background-color: #614b38;
}

.cta-bg-primary *, .cta-bg-secondary, .cta-bg-gradient *{
	color: #fff;
}

.cta-bg-secondary{
	background-color: #5f5846;
}

.cta-bg-gradient {
	background: rgb(97,75,56);
background: linear-gradient(45deg, rgba(97,75,56,1) 0%, rgba(95,88,70,1) 100%);
}

.cta-btn {
	padding: 10px 20px;
	background-color: #fff;
	color: #614b38;
	font-size: 16px;
	line-height: 1.2;
	margin-top: 20px;
	display: inline-block;
	font-weight: 600;
	text-decoration: none !important;
	border-radius: 35px;
}

@media(min-width:992px) {
	.cta-btn {
		padding: 15px 25px;
		font-size: 20px;
	}
}

.cta-btn:hover {
	background-color: #ddd7cb;
	color: #614b38;
}

.error-404 {
	float: left;
	width: 100%;
}
.section-block {
	padding: 70px 0;
}

h1.heading{
	font-size: 50px;
	line-height: 1.2;
	margin-bottom: 30px;
	padding: 0;
}
h2.heading{
	margin-bottom: 30px;
}
.error{
	color: red;
}